
/*  media query */

@media only screen and (min-width: 375px) and (max-width: 425px) {

  .filter-results, .card{
    border-left: none!important;
    border-right: none!important;;
  }
  /* .search-pagination-main{display: none;} */

  .pres-per-page .ph-results-per-page{
    margin-top: -2.4rem!important;
  }
  .search-pagination-main {
    display: block;
}
  .search-pagination  .ph-results-pagination {
    margin-left: 2.3rem;
}
  .search-pagination {
    margin-left: -3.9rem;
}

.pres-per-page {
  margin-left: 0;
}

.search-pagination .ph-results-pagination li a, .pres-per-page div span {
  font-size: 12.3px;
 
}
.pres-per-page {
  margin-left: 1rem;
}
.pres-per-page .ph-results-per-page select {
  display: inline-block;
  width: 43px;
  border: none;
}

  .support-list .ph-browse-list-view__left--img-container {
   
    min-width: auto!important;
    overflow: hidden;
    width: 25%!important;
}
  .result-email-btn {
    width: 100%;
    position: static;
    bottom: 0;
    /* top: 5.3rem; */
    /* padding-left: 12px;
    padding-right: 12px; */
    right: 0rem;
}

  .exact-match {
    box-sizing: border-box;
    height: 25.5px;
    width: 81.5px;
    border: 1.5px solid #FFB91D;
    border-radius: 4px;
    background: none !important;
    color: #000 !important;
}
  /* .support-list .ph-browse-list-view__left--img-container {
    margin: 0 0.325rem 0 7px !important;
} */
.createdate_publishdate div span{
  display: inline-block;
  
}
.link-label button {
  background: none;
  border-radius: 20px;
  margin-left: -0.5rem;
  padding: 2px;
  min-width: 35px;
  margin-top: 5px;
  font-size: 5px;
}
.description {
  text-align: left;
  margin-left: 0!important;
  
}
/* .support-list .ph-browse-list-view__left--img-container {
 
  width: 13% !important;
} */
.support-list .ph-browse-list-view__left--files div {
  margin-left: 29px;
  margin-top: 5px;
}
.description p {
  font-size: 13.3px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16.63px;
  margin-top: 17px;
  margin-bottom: 0;
}
  /* .link-label button{ border-radius: 20px;
    margin-left: -2.3rem;
    padding: 3px;
    min-width: 38px;
    margin-top: -55px;} */
  .link-label button {
    background: none;
    border-radius: 20px;
    margin-left: -2.2rem;
    padding: 3px;
    /* width: 64px; */
    margin-top: -5px;
}
  .ph-browse-list-view__left--title {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    /* line-height: 20px; */
   
  }
  .ph-browse-list-view__left--short-desc{
    margin: 8px 0px 8px 0px;
  }
  .ph-browse-list-view__right .entry {
    font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
        /* line-height: 20px; */
  }
  .ph-search-nav-tabs {
    /* flex-wrap: nowrap; */
    width: 100%;
    
}

.myparkersearch-tabs.nav-tabs .nav-item .nav-link {
  border: none;
  font-size: 13.3px;
  margin-bottom: -4px;
  padding: .2rem 4rem .325rem;
}
.myparkersearch-tabs.nav-tabs .nav-item .nav-link.active {
   border: none!important;
}
.hide-smallscreen{
  display: block;
}
/* .mob-tab{
  margin-left: 24px;
    margin-right: 24px;
} */
.ph-browse-list-view__content{
  display: block!important;
}
.ph-browse-list-view__right{
  border-top: 1px solid #e2e1dd;
  justify-content: center;
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  }

  .ph-browse-list-view__left{
width: 100%;
  }

  .support-list .ph-browse-list-view__left{
    width: 100%;
  }

  .support-list .ph-browse-list-view__left--container {
    margin: 0.4rem 0 0 -2rem !important;
}
.support-list .ph-browse-list-view__right {
  /* display: block;
  margin-top: 0;
  margin-right: 8px; */
  width: 100%;
}
  .support-list .ph-browse-list-view__left{
    align-items: normal!important;
  }
  .hide-mobile-lg{
    display:block;
  }

  .support-list .support-email{
    margin-bottom: 0!important;
    position: relative!important;
    margin-left: 0;
  }

  .support-list .ph-browse-list-view__product {
    margin: 0px !important;
}



.support-email a {
  min-width: 100% !important;
  /* margin-left: -16px!important; */
  margin-bottom: 0!important;
  position: relative!important;
}


.support-list .ph-browse-list-view__right{
  margin-top: -38px!important;
  border-top: unset!important;
}

.support-list .ph-browse-list-view__right .entry {
  
  max-width: 100%!important;
  padding-left: 8px;
        padding-right: 8px !important;
}

.support-list .ph-browse-list-view__right--literature {
  text-align: left!important;
}
.support-list .ph-browse-list-view__left--short-desc {
  /* margin: 8px 0 0 -1.39rem!important; */
  font-size: 13.3px;
  /* line-height: 16.63px; */
}

/* .support-list .support-img{
  width: 23% !important;
  padding-left: 5px;
} */

.support-list .ph-browse-list-view__left--title {
  color: #000000;
  
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
}

.support-email span{
  font-size: 13.3px;
  letter-spacing: 0;
  /* line-height: 1.8; */
}

.srp-header{display: block;}
/* .srp-header .showing-top-results{flex: 1;} */
.srp-header select{
  width: 277px!important;
  border: 1px solid #424242;
  border-radius: 4px!important;
  margin-right: 0;
  height: 32px;
        padding: 0 0 0 8px;
        margin-top: 20px;
}
.sortby h4{
 
  color: #000000;
  font-size: 13.3px;
  font-weight: 500;
  letter-spacing: 0;
  /* line-height: 1.4; */
  margin-left: 0px;
  margin-top: 26px;
}
.sortby {
  display: flex;
  height: auto !important;
  margin-left: 12px;
  margin-top: -31px;
}
.showing-top-results h4{
 margin-left: 11px;
        margin-top: 12px;
        /* margin-bottom: 12px; */
        font-size: 13.3px;
        line-height: 16.63px;
        color: #000;
}
.support-list .ph-browse-list-view__right {
  /* margin-top: 16px; */
  margin-right: 8px;
}

.mobile-tabs{
  color: #000;
        border: 1px solid #000000;
        font-size: 16px;
        text-align: center;
        border-radius: 4px;
}
/* .ph-results-pagination{display: none!important;} */
.table-responsive{
  width: 300px;
    overflow-y: scroll;
}
/* .search-pagination-main{
  display: none;
} */

.filter-list {
  margin-top: 8px;
  margin-bottom: 8px;
}
.srp-area {
 
  margin-top: 16px;
}

.mobile-tabs {

  margin-bottom: 11.5px;
}
.filter-list button{font-size: 13.3px;line-height: 16.63px;}
.support-email a {
    margin-left: 0;
    /* min-width: 200px !important; */
    margin-bottom: 0!important;
}
.createdate_publishdate {
  display: flex;
  margin-left: -2.7rem;
  margin-top: 1.4rem;
}

.support-content-left p {
  margin-bottom: 0;
  font-size: 13.3px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16.63px;
}
.support-list .ph-browse-list-view__left--img-container img {
  height: 42px !important;
  left: 0.7rem!important;
  /* max-width: none; */
  /* position: relative; */
  /* transform: translate(-50%); */
  width: 40px;
}
/* .support-list .ph-browse-list-view__left--container {
  width: 74%;
} */
.support-list .ph-browse-list-view__left--img-container {
  margin: 0 0 0 0 !important;
  /* margin-left: -2.5rem !important; */
}
.description {
  margin-top: 2rem;
}

}

@media only screen and (min-width: 768px) and (max-width: 1023px) {

  .support-list .ph-browse-list-view__left--container {
    height: auto;
    margin-top: -14px !important;
    margin-left: -1rem;
  }

  .ph-results-per-page select {
    display: inline-block;
    width: 54px;
    border: none;
}

  .pres-per-page .ph-results-per-page{
    margin-left: 0rem;
  }
  .description p {
    width: 274px;
    /* white-space: nowrap; */
    /* text-wrap: nowrap; */
    overflow: hidden;
    word-wrap: break-word;
    text-overflow: ellipsis;
}
  .result-email-btn {
    position: absolute;
    bottom: 0;
    top: 5.8rem;
        right: 8.1rem;
}
  .exact-match {
    box-sizing: border-box;
    height: 25.5px;
    width: 81.5px;
    border: 1.5px solid #FFB91D;
    border-radius: 4px;
    background: none !important;
    color: #000 !important;
}
  .cross-ref {
    padding: 10px;
  }
  .ph-search-nav-tabs {
    position: relative;
    right: 0;
    left: 0rem;
  }
  .ph-search-contents__results {
  
    width: 100%;
  }
  .myparkersearch-tabs.nav-tabs .nav-item {
    /* position: absolute; */
    margin: 0 .5rem;
    position: relative;  
    left: -5rem;
  }

  .support-list .support-email {
    margin-bottom: 0;
    margin-left: 0;
    padding-top: 28px;
}

.srp-header select{
  width: 124pxpx!important;
  border: 1px solid #000000;
  border-radius: 4px!important;
  margin-right: 12px;
  height: 36px;
  color: #000;
  
}

.support-list .ph-browse-list-view__right {
  margin-top: 0;
  margin-right: 8px;
}


.myparkersearch-tabs.nav-tabs .nav-item .nav-link {
  border: 1px solid #bcbcbc;
  font-size: 13.3px;
  margin-bottom: -4px;
 
  line-height: 16.63px;
}

.showing-top-results h4 {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
    color: #000000;
}
.srp-header select {
  width: 124px !important;
  border: 1px solid #000000;
  border-radius: 4px !important;
  margin-right: 19px;
  /* margin-top: 10px; */
  height: 34px;
  color: #000;
}
.sortby {
  height: 32px !important;
}
.sortby h4{
  color: #000000;
    font-size: 13.3px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16.63px;
    margin-right: 5px;
    /* margin-top: 24px; */
}

.ph-browse-list-view__left--title {
  font-size: 16px;
  line-height: 20px;
}
.ph-browse-list-view__left--short-desc {
  display: inline-block;
  font-size: 13.3px;
  line-height: 16.63px;
}
.support-email a{
  width: 200px;
        font-size: 13.3px;
        line-height: 16.63px;
        margin-left: -12px;
        margin-bottom: 0!important;
}

.search-pagination-main{
  display: flex;
}
/* .search-pagination{
  flex: 2;
} */
.ph-results-pagination {
 
  margin: 1rem 0 0 1.8rem;
 
}
.pres-per-page{
  margin-right: -1rem;
  margin-left: 0;
}
.hide-mobile{display: none!important;}
.filter-list {
  margin-top: 8px;
  margin-bottom: 8px;
}
.nav-tabs {
  margin-bottom: 0!important;
}
.hide-largescreen hr {
  display: none;
}
.srp-area{
  margin-top:16px;
}
/* .hide-largescreen{
  margin-top:16px;
  margin-bottom:16px;
} */
    .filter-list {
        margin-top: 17px;
        margin-bottom: 16px;
    }
    
.createdate_publishdate{display: block;}
.createdate_publishdate div{
  /* flex: 1; */
  padding-top: 8px;
}

/* .description{
  
  text-align: left;
  margin-left: -124px;

} */
.description p, .description h4{font-size: 13.3px;}
.support-list .support-email {
  margin-top: 0;
  margin-left: 0;
  padding-top: 8px;
}

.link-label button{background: none;border-radius: 20px; margin-left: -0.2rem;
  padding: 4px;}
.support-content-left p{
  font-size: 13.3px;
    letter-spacing: 0;
    font-weight: 500;
    line-height: 16.63px;
    margin-top: 10px;
    
}

.support-content-left h4{
  font-size: 16px;
font-weight: 500;
letter-spacing: 0;
line-height: 20px;
}
.description div{
  font-size: 13.3px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 17.6px;
}

.ph-browse-list-view__left--files {
  padding-bottom: 0;
  padding-top: 0;
}

.support-list .ph-browse-list-view__product{
min-height: 140px;
}
/* .support-list .ph-browse-list-view__left--img-container {
  margin: -2.5rem 0.325rem 0 7px !important;
} */
/* .support-list .ph-browse-list-view__left--container {
 margin-top: 0 !important;
} */
.support-list .ph-browse-list-view__right {
margin-top: 0;
margin-right: 12px;
}
.support-list .ph-browse-list-view__left--files div {
  margin-left: -0.5rem;
}
.support-list .ph-browse-list-view__left--img-container {
  margin: 0rem 0.325rem 0 5px !important;
  /* min-width: 20px !important; */
  overflow: hidden;
  width: 24% !important;
}
.support-list .ph-browse-list-view__left--img-container img {
  /* height: 69px;
  left: 50%; */
  /* max-width: none; */
  position: relative;
  transform: translate(-50%);
  /* width: 59px; */
}
.support-list .ph-browse-list-view__right {
  margin-top: 0;
  
}
.support-email a {
  width: 200px;
  font-size: 13.3px;
  line-height: 16.63px;
  margin-left: -60px;
  /* margin-bottom: -35px !important; */
}
.link-label button {
  background: none;
  border-radius: 20px;
  padding: 4px;
  /* min-width: 68px; */
  margin-top: 0;
   margin-left: 0;
}
.support-list .ph-browse-list-view__left--img-container {
  margin: 0 0.325rem 0 5px !important;
  /* min-width: 20px !important; */
  overflow: hidden;
  width: 24% !important;
}
.support-content-left p {
  /* font-size: 13.3px;
  letter-spacing: 0;
  line-height: 16.63px; */
  margin: 2.5rem 0 0;
  font-weight: 500;
  margin-top: 10px;
}
.description p{
  margin: 0;
  /* width: 248px; */
}
.description {
  text-align: left;
  margin-left: 0!important;
  /* margin-top: 1rem; */
}
.support-list .ph-browse-list-view__left{
  width: 100%;
  
}
.support-list .ph-browse-list-view__right{
  width: 40%;
  
}
.support-list .ph-browse-list-view__left--img-container img{
  margin-top: 0;
}
.products-lists .ph-browse-list-view__right {
  width: 50%!important;
}

.support-list .ph-browse-list-view__left--files {
  padding-bottom: 0;
  padding-top: 0;
  /* text-align: left; */
  margin-left: 0;
  margin-top: 5px;
}
.filter-results, .card{
  border-left: none!important;
  border-right: none!important;;
}
}

@media screen and (min-width: 1024px) and (max-width: 1100px) {

  .filter-results, .card{
    border-left: none!important;
    border-right: none!important;;
  }
  .pres-per-page .ph-results-per-page{
    margin-left: 4rem;
  }
  /* .filter-area{display: none;} */
  .result-email-btn {
    position: absolute;
    bottom: 0;
    top: 5.8rem;
    right: 9.7rem;
}

.result-email-btn{
  position: absolute;
    bottom: 0;
    top: 5rem;
    right: 11.5rem;
}
  /* .support-list .ph-browse-list-view__left {
    width: 60%;
} */
  .support-list .ph-browse-list-view__right {
    display: block;
    /* margin-top: 0;
    margin-right: 8px; */
    /* width: 50%; */
}
  .support-email a {
    margin-left: -0.5rem;
    min-width: 200px !important;
    margin-bottom: 0 !important;
    margin-top: 21.6px !important;
}

  .myparkersearch-tabs.nav-tabs {
    margin: 1rem 0 3.2rem 0.5rem;
    padding: 0;
     /* justify-content: left!important;  */
}

.ph-search-nav-tabs {
  position: relative;
  right: 0;
  left: -0.7rem;
}

.ph-search-contents__results {
  width: 100%;
}

.myparkersearch-tabs.nav-tabs .nav-item {
  /* position: absolute; */
  margin: 0 .5rem;
  position: relative;
  left: -9.6rem;
}
.support-list .support-email {
  margin-top: 0;
  margin-left: 0;
  padding-top: 8px;
}

.srp-header select{
  width: 124px!important;
  border: 1px solid #000000;
  border-radius: 4px!important;
  margin-right: 19px;
  height: 36px;
  color: #000;
}

/* .support-list .ph-browse-list-view__right{
  margin-top: -67px;
    margin-right: -12px;
} */

.myparkerserach-tabs.myparkersearch-tabs.nav-tabs .nav-item .nav-link {
  border: 1px solid #bcbcbc;
  font-size: 13.3px;
  margin-bottom: -4px;
 
  line-height: 16.63px;
}

.showing-top-results h4 {
  font-size: 16px;
  margin-left: 13px;
  color: #000000;
  line-height: 20px;
}
.srp-header select {
  width: 124px !important;
  border: 1px solid #000000;
  border-radius: 4px !important;
  margin-right: 19px;
  /* margin-top: 10px; */
  height: 34px;
  color: #000;
}
.sortby {
  height: 32px !important;
}
/*.srp-header h4{
  color: #000000;
    font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 20px;
}*/

.ph-browse-list-view__left--title {
  font-size: 16px;
  line-height: 20px;
}
.ph-browse-list-view__left--short-desc {
  display: inline-block;
  font-size: 13.3px;
  line-height: 16.63px;
}
.search-pagination-main{
  display: flex;
}
.search-pagination{
  flex: 1;
  margin-left: -12rem;
}
.search-pagination .ph-results-pagination {
 
  margin: 1rem 0 0 -5.2rem;
 
}
.pres-per-page{
  margin-right: 15rem;
  margin-top: 0.7rem;
}
.hide-largescreen{display: none;}
.support-email span {
    font-size: 13.3px;
    line-height: 16.63px;
}
.nav-tabs {
        margin-bottom: 16px !important;
}
.filter-list {
  margin-top: 16px;
  margin-bottom: 16px;
}
.filter-list button {
 
  color: #000000;
 
  font-size: 13.3px;
  font-weight: 500;
 
  line-height: 16.63px;
  
}
    .sortby h4 {
        font-size: 13.3px;
        line-height: 16.63px;
        /* margin-top: 22px; */
    }
    /* .support-list .ph-browse-list-view__left--img-container {
      margin: 0 0.325rem 0 7px !important;
   } */
   .support-list .ph-browse-list-view__left--container {
    /* margin-top: 0 !important; */
    margin-left: -1rem;
   }
   .support-list .ph-browse-list-view__right {
    margin-top: 0;
    margin-right: 0;
}
.support-list .ph-browse-list-view__left--files div {
  margin-left: -0.5rem;
        margin-top: 0.2rem;
}

.link-label button {
  background: none;
  border-radius: 40px;
  padding: 4px;
  min-width: 59px;
  margin-top: 0;
  margin-left: 0;
}

}

/* @media screen and (min-width: 1365px){

  .nav-tabs {
    margin: 1rem 0 3.2rem 0.5rem;
    padding: 0;
      justify-content: center!important;  
}

.ph-search-nav-tabs {
  position: relative;
  right: 0;
  left: -4.5rem;
}

} */

@media only screen and (min-width: 1440px) {
  .filter-results .card-header{
    border: 1px solid #000;
    background: #ffffff !important;
    padding: 12px;
  }
  .sidepanel{display: none!important;}
  .result-email-btn {
    position: absolute;
    bottom: 0;
    top: 5.8rem;
    right: 9.7rem;
}
  /* .support-list .ph-browse-list-view__left--container {
    width: 74%;
  } */

  .exact-match {
    box-sizing: border-box;
    height: 25.5px;
    width: 81.5px;
    border: 1.5px solid #FFB91D;
    border-radius: 4px;
    background: none !important;
    color: #000 !important;
}

/* .pres-per-page {
    margin-right: 1rem !important;
} */
.search-pagination {
  margin-left: 0;
}
  .cross-ref .showing-top-results h4{
  
    font-size: 19.2px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
  }
  .cross-ref .showing-top-results{ margin-top: -7px!important;}

  .support-list .ph-browse-list-view__left--img-container {
    margin: 0 0.325rem 0 7px !important;
}
.support-list .ph-browse-list-view__left--files div {
  margin-left: -0.5rem;
}

.link-label button {
  background: none;
  border-radius: 20px;
  margin-left:0;
  min-width: 64px;
  margin-top: 5px;
}


.support-list .ph-browse-list-view__left--container {
  /* margin-top: -3rem !important; */
  margin-left: -0.5rem;
}
  .myparkersearch-tabs.nav-tabs {
    margin: 1rem 0 3.2rem 0.5rem;
    padding: 0;
     /* justify-content: center!important;  */
}

.ph-search-nav-tabs {
  position: relative;
  right: 0;
  left: 0rem;
}
.myparkersearch-tabs.nav-tabs .nav-item {
  position: absolute;
  margin: 0 .5rem;
  position: relative;
  left: -5rem;

}
/* .support-list .ph-browse-list-view__left {
  width: 70%;
} */
.support-list .ph-browse-list-view__right {
  margin-top: 0px;
  margin-right: 8px;
  /* width: 35%; */
}

.showing-top-results h4{
  margin-left: 16px;
  margin-top: 19px;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.myparkersearch-tabs.nav-tabs .nav-item .nav-link{
  font-size: 13.3px!important;
}
.filter-list button{
  font-size: 13.3px;
  line-height: 16.63px;
}

.sortby h4{
  font-size: 13.3px;
  line-height: 16.63px;
}

.support-email a{
  margin-bottom: 0!important;
  line-height: 12.63px;
 
}

.sortby select{
  /* padding: 8px; */
  height: 32px;
  min-width: 124px !important;
  /* margin-top: 13px; */
  margin-right: 12px;
}
.sortby {
 
  margin-top: 1.5rem;
}

.ph-browse-list-view__left--title{

  font-size: 16px;
    line-height: 20pxpx;
}

.support-email span{
  font-size: 13.3px;
    line-height: 12.63px;
}

.search-pagination-main{
  display: flex;
}
.search-pagination{
  flex: 1;
}
.ph-results-pagination {
    margin: 1rem 0 0 -1.7rem;
  
}
.pres-per-page{
  margin-right: -1rem;
}
.ph-results-per-page {
 
  /* float: none; */
  margin-left: 0px;
}
.hide-largescreen{display: none;}

.createdate_publishdate{display: flex;}
.createdate_publishdate div{
  flex: 1;
  padding-top: 8px;
}

.description{
  
  text-align: left;
  /* margin-top: 1rem; */
  margin-left: 0;

}
.description p, .description h4{font-size: 13.3px;}
.support-list .support-email {
  margin-top: 0;
  margin-left: 0;
  padding-top: 8px;
}

.link-label button{background: none;border-radius: 20px;       margin-left: 0;padding: 4px;}
.support-content-left p{
  font-size: 13.3px;
    letter-spacing: 0;
    line-height: 16.63px;
    font-weight: 500;
    /* margin-top: 10px; */
}

.support-content-left h4{
  font-size: 16px;
font-weight: 500;
letter-spacing: 0;
line-height: 20px;
}
.description div{
  font-size: 13.3px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 17.6px;
}

.ph-browse-list-view__left--files {
  padding-bottom: 0;
  padding-top: 0;
}

.support-list .ph-browse-list-view__product{
min-height: 140px;
}

/* .support-list .ph-browse-list-view__left--container{
  width: 70%;
} */

}

 @media screen and (min-width: 1920px) {
  .sidepanel{display: none;}
  .result-email-btn {
    position: absolute;
    bottom: 0;
    top: 4.1rem;
    right: 10.5rem;
}
  .search-pagination {
    margin-left: 0;
}

.pres-per-page {
  margin-right: -1rem !important;
  margin-left: 0;
}
  .support-list .ph-browse-list-view__right{
    margin-top: 0;
    width: 27%;
  }

  .cross-ref .showing-top-results h4{
  
    font-size: 19.2px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
  }
  .cross-ref .showing-top-results{ margin-top: -7px!important;}

  .hide-largescreen{display: none;}

  /* .nav-tabs {
    margin: 1rem 0 3.2rem 0.5rem;
    padding: 0;
     justify-content: center!important; 
} */

  /* .ph-search-nav-tabs {
    position: relative;
    right: 0;
    left: 0!important;
  } */
  .myparkersearch-tabs.nav-tabs .nav-item .nav-link{
    font-size: 13.3px!important;
  }
  .myparkersearch-tabs.nav-tabs .nav-item {
    position: absolute;
    margin: 0 .5rem;
    position: relative;
    left: -11.5rem;
  }
  .showing-top-results h4{
    font-size: 16px;
    line-height: 20px;
    margin-left: 16px;
    margin-top: 18px;
    margin-bottom: 18px;
    color: #000000;
  }
  .sortby select {
    /* padding: 8px; */
    height: 32px;
    width: 124px !important;
    /* margin-top: 12px; */
    /* margin-bottom: 12px; */
    margin-right: 16px;
    font-size: 13.3px;
}
.sortby {
  margin-top: 1.8rem;
}
.ph-browse-list-view__left--title{

  font-size: 16px;
    line-height: 20px;
}


 .support-email a {
    margin-left: -12px;
    min-width: 200px !important;
    margin-top: 37.6px !important;
    position: absolute;
}
.support-email span{
  font-size: 13.3px;
    line-height: 12.63px;
}
.search-pagination-main{
  display: flex;
}
.search-pagination{
  flex: 2;
}
.ph-results-pagination {
    margin: 1rem 0 0 -1.7rem;
  
}

.createdate_publishdate{display: flex;}
.createdate_publishdate div{
  flex: 1;
  padding-top: 8px;
}

.description{
  
  text-align: left;
  margin-left: 0;
  /* margin-top: 1rem; */

}
.description p, .description h4{font-size: 13.3px;}
.support-list .support-email {
  margin-top: 0;
  margin-left: 0;
  padding-top: 8px;
}

.link-label button{background: none;
  border-radius: 20px;
  margin-left: 0;
  padding: 4px;
}
.support-content-left p{
  font-size: 13.3px;
    letter-spacing: 0;
    line-height: 16.63px;
    font-weight: 500;
    margin-top: 10px;
}

.support-content-left h4{
  font-size: 16px;
font-weight: 500;
letter-spacing: 0;
line-height: 20px;
}
.description div{
  font-size: 13.3px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 17.6px;
}

.ph-browse-list-view__left--files {
  padding-bottom: 0;
  padding-top: 0;
}

.support-list .ph-browse-list-view__product{
min-height: 140px;
}
.exact-match {
  box-sizing: border-box;
  height: 25.5px;
  width: 81.5px;
  border: 1.5px solid #FFB91D;
  border-radius: 4px;
  background: none!important;
        color: #000!important;
}
.support-list .ph-browse-list-view__left--container {
  /* margin-top: -3rem !important; */
  margin-left: -12px;
}

.pres-per-page {
  margin-top: 16px;
  margin-right: 42px;
}

} 
