.ph-flex-table__header div:nth-child(3) {
    flex: 1;
}

.ph-flex-table__row td, .ph-flex-table__header th {
    min-width: 120px;
}

.ph-flex-table__header{
    padding: 0.25rem;
}

.ph-flex-table__item {
    flex: 1;
}

.ph-flex-table__item {
    -ms-flex: 1;
    flex: 1;
}

.row-first-part{
    border-top: 1px solid #e2e1dd;
    border-left: 1px solid #e2e1dd;
    border-right: 1px solid #e2e1dd;
    margin-top: 5px;
}

.row-second-part{
    border-bottom: 1px solid #e2e1dd;
    border-left: 1px solid #e2e1dd;
    border-right: 1px solid #e2e1dd;
}

.apiKey-display{
    display: flex;
    flex-direction: row;
    width:81.5%
}

.apiKey-display span {
    text-wrap-mode: nowrap;
    margin-right: 5px;
}

.ph_token_input{
    /* border-top:none !important; */
}


i.fa2{
    display: inline-block;
    padding: 1em 0.8em;
    cursor: pointer;
}

i.fa3{
    display: inline-block;
    padding-left: 0.8em !important;
    padding-right: 0.8em !important;
    cursor: pointer;
}

input[type="text"]{
    /* border-bottom:1px solid #8e8e8e;
    border-left:1px solid #8e8e8e;
    border-right:1px solid #8e8e8e; */
    margin-top:0px;
    margin-bottom:8px;
    padding:10px 25px 10px 10px !important;
}


@media screen and (max-width: 992px) {

    .ph-flex-table__header {
        flex-direction: row!important;
        min-width: 0px;
    }

    .ph-flex-table__row td, .ph-flex-table__header th {
        min-width: 0px !important;
    }

    .row-first-part {
        flex-direction: row!important;
    }
    .row-second-part {
        flex-direction: row!important;
    }

    .header-details {
        font-size: 14px;
        line-height: 36px;
        font-weight: 500;
        margin: 0 12px;
    }    
    
}


@media screen and (max-width: 470px) {

    .ph-modal__content {
        width: 80% !important;
        padding: 0 10px;
    }

    .ph-form-row__label {
        font-size: 0.875rem;
        font-weight: 400;
        padding: 0px;
    }
}

.text-c{
    text-align: center;
}

.ph-form-row__label{
    padding: 0;
}

.row.multiSelectDropdown div {
    padding: 0 !important;
}

  /* New */
#apiKeyTable.table{
width: 100%;
}
#apiKeyTable.table thead  {
    border: 1px solid rgb(197, 197, 197) !important;
}
#apiKeyTable.table thead th {
    cursor: pointer;
    min-width: 128px;
}
#apiKeyTable tbody td{
    text-align: center;
}
#apiKeyTable thead tr th{
    font-weight: bold;
    background-color: rgb(246, 246, 245) !important;
    padding: 0.5rem 0 1.5rem 0 !important;
    max-width: 268px !important;
}

#apiKeyTable tbody #apiKeyNoRecordsFound{
    border: 1px solid rgb(197, 197, 197) !important;
    border-bottom: 0 !important;
}
#apiKeyTable tbody #apikeyFirstRow{
    border: 1px solid rgb(197, 197, 197) !important;
    border-bottom: 0 !important;
}
#apiKeyTable tbody #apikeySecondRow{
    border: 1px solid rgb(197, 197, 197) !important;
    border-top: 0 !important;
}
#apiKeyTable tbody #apikeySecondRow .row .col-6{
    display: flex;
}
#apiKeyTable tbody #apiKeyEmptyRow{
    border: 0 !important;
}
#apiKeyTable tbody #apiKeyEmptyRow td{
    padding: 0.1rem !important;
}
#apiKeyTable tbody #apikeyFirstRow td i{
    cursor: pointer;
}
#apiKeyTable tbody #apikeySecondRow input{
    width: 80%;
}
@media (max-width: 768px) {
    #apiKeyTable tbody #apikeySecondRow input {
        width: 70%; 
    }
    #apiKeyTable{
        margin: 0 12px;
    }
}

.apiKeySearch{
    margin: 0;
}

.apiKeySearch label{
    font-weight: 500;
    font-size: 12px;
}
#apiKeySearchInput{
    height: 30%;
    font-family: Roboto, FontAwesome !important;
    width: 20%;
    border: 0.5px solid;
    border-radius: 0;
}
#apiKeySearchInput::placeholder{
    font-weight: 500;
    font-size: 12px !important;
}
@media screen and (max-width: 768px) {
    .apiKeySearch{
        margin: 0 12px;
    }
    #apiKeySearchInput{
        width: 60% !important;
    }
}


.api-key-pagination ul li a{
    border: 0 !important;
    font-size: 12px;
    margin-right: 2px;
    height: 30px;
    display: grid;
    place-items: center;
}

.api-key-pagination ul li.active a{
    background: transparent;
    color: black;
    border: 1px solid #ddd !important;
    
}
.api-key-pagination ul li a:hover{
    background: transparent;
    color: black;
}

.api-key-pagination .page-item:first-child .page-link{
    border-radius: unset !important;
}

.api-key-pagination .apiKey-ellipsis span{
    border: 0;
    background-color: transparent;
    color: black;
}
.apiKeyBottomPagination{
    margin: 2px 0    0 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.apiKeyBottomPagination .results-per-page{
    display: flex;
    flex-direction: row;
}
.apiKeyBottomPagination .results-per-page #itemsPerPage{
    margin-left: 3px;
    height: 35px;
    width: 55px;
    cursor: pointer;
    border: 1px solid #ddd;
}
.expandable-content {
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
  }
  
  .expandable-content.collapsed {
    max-height: 0;
  }
  
  .expandable-content.expanded {
    max-height: 500px; 
  }

  .apiKey-table-wrapper{
    height: auto !important;
    border: 0;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .apiKey-table-wrapper #apiKeyTable tr td.mobile-actions {
    display: none;
  }
  /* Card layout for mobile devices */
@media (max-width: 767px) {
    .apiKey-table-wrapper  {
      display: block;
      border: 0;
      height: auto !important;
      place-items: center;
    }
  
    .apiKey-table-wrapper #apiKeyTable {
      display: block;
      width: unset !important;
    }
  
    .apiKey-table-wrapper #apiKeyTable thead {
      display: none;
    }
  
    .apiKey-table-wrapper #apiKeyTable tbody {
      display: block;
      width: 100%;
    }
  
    .apiKey-table-wrapper #apiKeyTable tbody #apikeyFirstRow {
      display: block;
      width: 100%;
      border: 1px solid #ddd;
      border-radius: 5px 5px 0 0 ;
      padding: 10px;
      box-shadow:0 2px 4px rgba(0, 0, 0, 0.1);
    }
    
    .apiKey-table-wrapper #apiKeyTable tbody #apikeySecondRow{
        display: block;
        width: 100%;
        border: 1px solid #ddd;
        border-radius: 0 0  5px 5px ;
      margin-bottom: 5px;
      padding: 0 0 10px 0;
      box-shadow:0 2px 4px rgba(0, 0, 0, 0.1);
    }
    .apiKey-table-wrapper #apiKeyTable tbody #apikeyFirstRow td {
      display: flex;
      /* justify-content: space-between; */
      padding: 5px 0;
      text-align: left !important;
    }
  
    .apiKey-table-wrapper #apiKeyTable tbody #apikeyFirstRow td::before {
      content: attr(data-label);
      font-weight: bold;
      width: 30%;
      flex-shrink: 0;
    }
    .apiKey-table-wrapper #apiKeyTable tbody #apikeyFirstRow td span{
       overflow-wrap: anywhere;
    }
    
    .apiKey-table-wrapper #apiKeyTable tbody #apikeyFirstRow td div {
      flex: 2;
      text-align: right;
    }

    .apiKey-table-wrapper #apiKeyTable tbody #apiKeyEmptyRow{
        display: none;
    }
    .apiKey-table-wrapper #apiKeyTable tbody #apikeyFirstRow td:last-child {
        display: none;
    }
    .apiKey-table-wrapper #apiKeyTable #apikeyFirstRow td.mobile-actions {
       display: flex;
       justify-content: end;
      }
    
      .apiKey-table-wrapper #apiKeyTable #apikeyFirstRow td.mobile-actions i {
        margin-right: 10px; /* Add some spacing between icons */
      }
      
      .apiKey-table-wrapper #apiKeyTable #apikeySecondRow td{
        width: 100vw;

      }

      .apiKey-table-wrapper #apiKeyTable #apikeySecondRow .col-4 {
        width: 27%; /* Default width for col-4 */
      }
    
      .apiKey-table-wrapper #apiKeyTable #apikeySecondRow .col-6 {
        width: 73%; 
        display: flex;
      }
      .apiKey-table-wrapper #apiKeyTable #apikeySecondRow  .col-4 span{
        word-wrap: break-word;
        
      }
      .apiKey-table-wrapper #apiKeyTable #apikeySecondRow  .col-3{
        width: 100%;
      }
      .apiKey-table-wrapper #apiKeyTable #apikeySecondRow  .col-3 div{
        text-align: left !important;
        justify-content: start !important;
        margin-top: 10px;
      }
      .apiKeyBottomPagination{
        margin-right: 12px;
      }
      
      .apiKeySearch #apiKeySearchInput{
        width: 60% !important; 
      }
    
      

  }