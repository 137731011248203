.result-cert-header {
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #888 #e0e0e0; /* For Firefox */
}
.cert-search {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  width: 20%;
}
.filter-row {
  margin-bottom: 10px;
  margin-left: 20px;
  margin-top: 10px;
}
.filter-row span {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
}

.filter-row button {
  font-size: 13.3px;
  box-sizing: border-box;
  height: 33px;
  /* width: 89px; */
  background: none !important;
  border: 1px solid #000000 !important;
  border-radius: 4px;
  margin-left: 1rem;
}

.filter-row button i {
  font-size: 16px !important;
  font-weight: 400 !important;
  padding: 0 0 0 13px !important;
}
.hct-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  padding-bottom: 5px;
}

.qualityDocresultContainer .qualityDocResultTable {
  padding: 0px 10px 18px;
  border-bottom: 1px solid #b3b3b3;
  margin: 0;
}

.qualityDocresultContainer .qualityDocResultTable:last-child {
  border-bottom: none;
}

.qualitydocWrapper .qualityDocresultContainer {
  margin: 0 0 16px !important;
  background-color: #ffffff;
}

.qualitydocWrapper .docSearchInput {
  box-sizing: border-box;
  height: 40px;
  width: 576px;
  border: 1px solid #000000;
  border-radius: 4px;
  background-color: #ffffff;
}

.qualitydocWrapper .qualityDocsearch {
  display: flex;
  position: relative;
  height: 40px;
  cursor: pointer;
}

.qualitydocWrapper .qualityDocsearch .form-control-search {
  margin-bottom: 0px;
  border: none;
  padding-left: 10px;
  padding-top: 12px;
  padding-bottom: 11px;
  font-size: 16px !important;
  letter-spacing: 0;
  line-height: 21.6px;
  box-shadow: none !important;
  outline: none !important;
}

.qualitydocWrapper .qualityDocinput {
  width: 100%;
}

.qualitydocWrapper .qualitySearchIcon .btn,
.qualitydocWrapper .qualitySearchIcon .btn:focus,
.qualitydocWrapper .qualitySearchIcon .btn:active {
  min-width: 38px;
  border-radius: 0 4px 4px 0;
  box-shadow: none !important;
  border: none;
  height: 38px;
  outline: none !important;
}

.qualitydocWrapper .qualityDocsearch .close {
  margin-top: 8px;
  margin-right: 10px;
}

.qualitydocWrapper .qualityDocdocumentSearch {
  color: #000000;
  font-family: Roboto;
  font-size: 19.2px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 25px;
}

.qualitydocWrapper .marginLine {
  margin-bottom: 16px;
  margin-top: 16px;
}

.qualitydocWrapper .qualityDocTablekey {
  color: #000000;
  font-family: Roboto;
  font-size: 13.3px;
  font-weight: 500;
  line-height: 17.6px;
  text-align: left;
}

.qualitydocWrapper .qualityDocTableValue {
  color: #000000;
  font-family: Roboto;
  letter-spacing: 0;
  font-size: 13.3px;
  font-weight: 400;
  line-height: 16.63px;
  text-align: left;
}

.qualitydocWrapper .search-by-hct {
  color: #000000;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: 16px;
  margin-bottom: 11px;
}

.qualitydocWrapper .main-body-gen .line {
  box-sizing: border-box;
  width: 100%;
  height: 0;
  border: none;
  border-bottom: 1px solid #979797;
}

.qualitydocWrapper .my-parker-admin-gen {
  height: auto;
  color: #000000;
  font-size: 33.18px !important;
  letter-spacing: 0 !important;
  line-height: 49.76px !important;
  margin-left: 32px;
}

.qualitydocWrapper h1.my-parker-admin-gen {
  font-size: 33.18px !important;
  letter-spacing: 0;
  line-height: 49.76px !important;
}

.qualitydocWrapper .main-body-gen {
  background-color: #ffffff;
  min-height: 500px;
}

.qualitydocWrapper .account-information {
  height: 30px !important;
  color: #000000 !important;
  font-size: 23.4px !important;
  font-weight: 500 !important;
  letter-spacing: 0 !important;
  line-height: 29.25px !important;
}

.qualitydocWrapper .account-information-col h2,
.qualitydocWrapper .user-details-col h2,
.qualitydocWrapper .distributor-accounts-col h2 {
  margin-bottom: 0.2rem !important;
}
.button_column .btn-import-user {
  width: 80%;
  padding: 6px 20px;
  margin-left: 25px;
}
.filter-open-box {
  float: right !important;
  right: 0 !important;
  left: auto !important;
  border-top: none;
}
.sidehead-mobile {
  background-color: #424242;
  color: #fff;
}
.filter-text {
  margin-top: 0px;
}
.clear-all-text {
  margin-top: 0px !important;
}
.all-filter-mobile {
  text-align: right;
}
.all-filter-mobile .search-box {
  width: 50%;
  float: left;
  margin-top: -0.5rem;
  padding-bottom: unset;
}
.dummmy-img {
  width: 52px;
  height: 72px;
  border-radius: 4px;
  opacity: 0px;
  background: #d8d8d8;
}
.download-btn {
  background: #333333;
  color: #fff;
  border: 1px solid #333333;
}
.sidebar-filter-heading {
  margin: 10px;
  background: #000000 !important;
}
.sidebar-filter-heading .filterby {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}
.mob-open-filter-btn {
  width: 40%;
  margin-top: 0.5rem;
}
.no-result-section {
  height: 50vh;
}
.filter-selected-buttons {
  min-width: 85px;
}
.overlay-filter-open {
  display: block;
  position: fixed;
  top: 0;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.8);
}
@media screen and (min-width: 1024px) and (max-width: 1399px) {
  .qualitydocWrapper .main-body-gen .section-1 {
    margin-left: 24px !important;
    margin-top: 64px;
    margin-right: 24px !important;
  }

  .qualitydocWrapper .my-parker-admin-gen {
    height: 42px !important;
    color: #424242 !important;
    font-size: 33.18px !important;
    letter-spacing: 0 !important;
    line-height: 41.48px !important;
    margin-top: 10.02px !important;
  }
}

@media screen and (min-width: 1440px) and (max-width: 1600px) {
  .qualitydocWrapper .my-parker-admin-gen {
    height: 50px !important;
    color: #424242;
    font-size: 33.18px !important;
    letter-spacing: 0 !important;
    line-height: 49.76px !important;
  }

  .qualitydocWrapper .main-body-gen .section-1 {
    margin-left: 32px !important;
    margin-top: 64px !important;
    margin-right: 32px !important;
  }

  .qualitydocWrapper .my-parker-admin-gen {
    margin-left: 32px !important;
    margin-top: 10.02px !important;
  }
}

@media screen and (min-width: 1600px) and (max-width: 1919px) {
  .qualitydocWrapper .my-parker-admin-gen {
    margin-left: 72px !important;
    margin-bottom: 0 !important;
  }

  .qualitydocWrapper .my-parker-admin-gen {
    height: 50px !important;
    color: #000000;
    font-size: 33.18px !important;
    letter-spacing: 0 !important;
    line-height: 49.76px !important;
    margin-top: 10.02px !important;
  }

  .qualitydocWrapper .main-body-gen .section-1 {
    margin-left: 72px !important;
    margin-top: 64px !important;
    margin-right: 72px !important;
  }
}

@media screen and (min-width: 1920px) {
  .qualitydocWrapper .my-parker-admin-gen {
    margin-left: 72px !important;
    margin-bottom: 0 !important;
  }

  .qualitydocWrapper .my-parker-admin-gen {
    height: 50px !important;
    color: #000000;
    font-size: 33.18px !important;
    letter-spacing: 0 !important;
    line-height: 49.76px !important;
    margin-top: 11px !important;
  }

  .qualitydocWrapper .main-body-gen .section-1 {
    margin-left: 72px !important;
    margin-top: 64px !important;
    margin-right: 72px !important;
  }
}

/* styles for tablets */

@media only screen and (min-device-width: 481px) and (max-device-width: 767px) {
  .qualitydocWrapper .my-parker-admin-gen {
    margin-left: 0 !important;
  }

  .qualitydocWrapper .my-parker-admin-gen {
    color: #424242 !important;
    font-size: 27.65px !important;
    letter-spacing: 0 !important;
    line-height: 34.56px !important;
    margin-top: 9.44px !important;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
  .qualitydocWrapper .my-parker-admin-gen {
    margin-left: 24px !important;
  }

  .qualitydocWrapper .my-parker-admin-gen {
    height: 35px !important;
    color: #424242;
    font-size: 27.65px !important;
    letter-spacing: 0 !important;
    line-height: 34.56px !important;
    margin-top: 9.44px !important;
  }

  .qualitydocWrapper .main-body-gen .section-1 {
    margin-left: 24px !important;
    margin-top: 64px !important;
    margin-right: 24px !important;
  }
  .all-filter-mobile .search-box {
    padding-bottom: 0px;
    margin-top: -5px;
  }
  .action-col {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .download-btn {
    width: 200px !important;
    float: right;
  }
  .print-btn {
    width: 200px !important;
    float: right;
  }
}

@media only screen and (max-width: 767px) {
  .qualitydocWrapper .qualityDocresultContainer {
    margin-left: 16px;
    margin-right: 16px;
  }
}

@media only screen and (max-width: 600px) {
  .qualitydocWrapper .docSearchInput {
    width: 100%;
  }
}

/* styles for mobiles */
@media only screen and (max-width: 480px) {
  .qualitydocWrapper h1.my-parker-admin-gen {
    font-size: 23.4px !important;
    letter-spacing: 0;
    line-height: 26px !important;
  }

  .qualitydocWrapper .section-1 {
    margin-left: 0 !important;
    margin-top: 32px;
    margin-right: 0 !important;
  }

  .qualitydocWrapper .my-parker-admin-gen {
    color: #000000;
    font-size: 23.4px !important;
    letter-spacing: 0 !important;
    line-height: 23.4px !important;
    margin-left: 0 !important;
    margin-top: 11px !important;
  }

  .qualitydocWrapper .account-information {
    color: #000000;
    font-size: 19.2px !important;
    font-weight: 500 !important;
    letter-spacing: 0 !important;
    line-height: 24px !important;
  }

  .qualitydocWrapper .account-information-col h2 {
    margin-bottom: 0rem !important;
  }

  .qualitydocWrapper .qualityDocResultTable {
    padding: 0px 10px 16px;
  }

  .qualitydocWrapper .qualityDocsearch .form-control-search {
    line-height: 20px;
  }

  .qualitydocWrapper .qualityDocTablekey {
    font-size: 13.3px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 17.6px;
  }

  .qualitydocWrapper .qualityDocTableValue {
    font-size: 13.3px;
    letter-spacing: 0;
    line-height: 17.6px;
  }
}
@media (min-width: 375px) and (max-width: 425px) {
  .hct-checkbox {
    top: -20px;
    padding: 0px;
  }
  .hct-title {
    /* margin-left: 10px; */
    font-weight: 500;
    font-size: 13.3px;
    line-height: 16.63px;
    letter-spacing: 0px;
  }
  .content-col {
    padding-left: 0px;
  }
  .action-col {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-left: 0px;
  }
  .download-btn {
    width: 153px !important;
    margin-left: 0px !important;
  }
  .print-btn {
    width: 153px !important;
    margin-left: 5px !important;
  }
  .dummmy-img {
    float: right;
  }
  .img-col {
    top: -25px;
  }
  .all-filter-mobile .search-box {
    width: 100%;
  }
  .page-main-heading {
    font-size: 19.2px !important;
    font-weight: 400;
    line-height: 24px !important;
    text-align: left;
    margin: 10px 0px !important;
  }
  .mobile-filter-box-open {
    margin-top: 8rem;
    border-radius: 16px 16px 0px 0px;
  }
  .matCert-result-heading {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
  }
  .mob-open-filter-btn {
    width: 100%;
  }
}
