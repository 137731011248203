#createApiKeyModal .ph-form-row__input::placeholder {
    font-size: 15px !important; 
    font-weight: 400 !important;
    color: rgb(144, 143, 143) !important; 
  }

.checkbox-container label{
    margin: 0 !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    color: #000 !important;
    cursor: pointer;
}
#createApiKeyModal input[type="email"]{
    border: 1px solid rgb(231, 231, 231)
}
#createApiKeyModal input[type="email"]:focus{
    border-color: #000;
}

#createApiKeyModal .dropdown-container {
    position: relative;
    width: 100%;
  }
  
  #createApiKeyModal .dropdown-header {
    border: 1px solid #ccc;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  #createApiKeyModal .dropdown-arrow {
    margin-left: 10px;
  }
  
  #createApiKeyModalr .dropdown-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid #ccc;
    background-color: white;
    z-index: 1000;
    max-height: 200px;
    overflow-y: auto;
  }
  
  #createApiKeyModal .checkbox-container {
    padding: 10px;
    display: flex;
    align-items: center;
  }
  
  #createApiKeyModal .checkbox-container input {
    margin-right: 10px;
  }

  #createApiKeyModal div .row .ph-form-import-row div div {
    padding: 0px !important;
  }

  #createApiKeyModal .searchWrapper {
    border: 1px solid #ccc;
    border-radius: 1px !important;
    min-height: 22px;
    padding: 0px !important;
    position: relative;
}   

#createApiKeyModal .multiSelectContainer input:focus {
    outline: none !important;
    border: 0 !important;
    box-shadow: none !important;
}
#createApiKeyModal .multiSelectContainer input:hover {
    outline: none !important;
    border: 0 !important;
}

#createApiKeyModal .multiSelectContainer input::placeholder {
    font-size: 15px !important; 
    font-weight: 400 !important;
    color: rgb(144, 143, 143) !important; 
  }
  #createApiKeyModal .multiSelectContainer .chip {
    background: rgb(144, 143, 143) !important;
  }

  #createApiKeyModal .multiSelectContainer .optionContainer li:hover{
    background: rgb(144, 143, 143) !important;
  }