
/* Filter sidebar Css */
.filter-area .card-header{
  /* border: 1px solid #000; */
  background: #ffffff!important;
  padding: 12px;

}
.filter-area .card-header h5 button{    
  color: #000000 !important;
  background: none;
  border: unset;
  text-align: left;
}


.sidehead-box{border-radius: 4px 4px 1px 1px;
background-color: #424242;
padding-top: 10px;
padding-bottom: 10px;
padding-left: 12px;
color: #ffffff;

}

.filter-results{margin-top: 16px;margin-bottom: 16px;}
.sidebar-search-box-container .card-header h5{
  margin: 0!important;
}
/* .sidebar-search-box-container {margin-top: 1rem!important;} */

.sidebar-search-box-container .card-body{
  padding: 10px 0 0 0!important;
}

.filter-area .ph-filter-group__search {
border: 1px solid #000000;
border-radius: 4px;
background-color: #FFFFFF;
margin: 0!important;
  position: relative;
}

.ph-filter-group__search input[type="text"] {
  margin-top: 0px;
  margin-bottom: 0!important;
  /* padding: 0!important; */

}


details {
position: relative;
font-size: 16px;
width: 100%;
padding: 0.5rem 0.5rem 0.5rem 0.5rem;
}

details summary {
cursor: pointer;
list-style: none;
padding-right: 25px;
position: relative;
/* padding-bottom: 12px; */
}

details summary::after {
font-family: "Font Awesome 5 Free";
font-weight: 900;
content: "\f078";
font-size: 16px;
position: absolute;
right: 0;
top: 0;
transition: transform 0.3s ease;
}

details[open] summary::after {
content: "\f077";
}
.filter-label label{
font-size: 13.3px!important;
letter-spacing: 0;
font-weight: 400;
/* line-height: 16.63px!important; */
margin: 0.6rem 0 0 0!important;
color: #000000;
}

.content {
display: none;
}
.expand-list{
/* height: 12px;
width: 12px; */
/* background-color: #000000; */
font-size: 13.3px;
letter-spacing: 0;
line-height: 17.6px;
padding-left: 0.7rem !important;
}

details ul {
  list-style: none;
  margin-left: -1.9rem;
}


.search-box{display: flex;}
.search-box{
/* border: 1px solid #000000; */
  /* border-radius: 4px; */
  background-color: #FFFFFF;

}

.srch-box{
padding-left: 12px;
padding-right: 12px;
}
.srch-btn .btn{
    min-width: 30px;
  min-height: 24px;
  border-radius: 0px 4px 4px 0px;
  border: 1px solid #000000;
  border-left: unset;
  padding: 7px 7px 7px 7px;
}
.srch-btn .btn i{
font-size: 24px;
  font-weight: 400;

}
.srch-input{
/* border-color: #000 !important; */
width: 100%;
}
.srch-input input[type="text"]{

min-height: 40px !important;
border: 1px solid #000000;
  border-right: unset;
border-radius: 4px 0 0 4px;
/* margin: 0; */
}

.srch-btn .btn i {
font-size: 24px;
font-weight: 400;
padding: 1px;
}

/* .sidehead-box{
display: flex;

} */
.filterby_clear_all{
  float: right;
  margin-top: -1.2rem;
  margin-right: 0.6rem;
/* position: relative;
right: 0;
left: 10rem; */
text-decoration: underline;
}

input::placeholder{
color: #000000;
}
.product-card {
border: 1px solid #000;
border-radius: 4px;
/* border-left: 1px solid #000; */
/* border-top: 1px solid #000; */
/* border-radius: 4px 4px 1px 1px; */
}
.product-list{
margin-top: 16px;
}
/* .product-card:last-child{
 border-bottom: 1px solid #000000; */
 /* border-bottom-left-radius: 25px; */
/* }  */
/* .product-card{
border-left: 1px solid #000;
border-right: 1px solid #000;
border-bottom: 1px solid #000;
} */
.show-expanded .view-more-list{
font-size: 13.3px!important;
margin-left: 0.8rem;
color: #000;
letter-spacing: 0;
line-height: 17.6px!important;
}
.show-expanded .expand-list{
position: absolute;
   left: 0;
       margin-top: 0rem !important;
    padding-left: 0.3rem !important;
   color: #000;
   font-size: 13.3px!important;
   letter-spacing: 0;
   line-height: 17.6px!important;

}

.division-search{
padding: 0!important;
}

.show-expanded{
margin-top: 8px;
}

.break-line{display: none;}
input::placeholder{
color: #000!important;
font-size: 16px!important;
letter-spacing: 0;
line-height: 20px!important;
}

.radio_label li label{
  display: inline-flex;
  align-items: center;
  /* margin: 0!important; */
}
.radio_label li label::before {
  content: '';
 
  margin-right: 0.5em;
  margin-top: 0.7rem;
}

input[type="radio"]:checked + label {
  font-size: 13.3px !important;
    letter-spacing: 0;
    font-weight: 400;
    line-height: 16.63px !important;
    margin: 0.6rem 0 0 0 !important;
    color: #000000;
}

  .radio_label{
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .search-box-product{
    display: flex;
    border-radius: 4px;
    background-color: #FFFFFF;
}


input:focus{
  outline: 0!important;
}
button:focus{
  outline: 0!important;
}
.bottom-btn{
  display: none;
}
.sidebar-search-box-container .card{
  border-left: unset;
    border-right: unset;
    /* margin-left: 12px;
    margin-right: 12px; */
    border-radius: 0!important;
}

/* media qury for sidebar*/



@media (min-width: 1024px) and (max-width: 1100px)  {

  .filter-results .card-header {
    border-left: unset !important;
    border-right: unset !important;
  }

.support-type .expand-list{
    margin-top: 0.2rem !important;
    margin-left: 0.7rem !important;
}
  .bottom-btn{
  display: block;
}

      .show-expanded .view-more-list {
        font-size: 13.3px!important;
        
        color: #000;
        letter-spacing: 0;
        line-height: 17.6px!important;
        margin-left: 1.2rem!important;
    }

   .support-type .show-expanded .expand-list {
      margin-top: 0.3rem !important;
      margin-left: 0.7rem !important;
  }
    .show-expanded .expand-list{
      font-size: 13.3px!important;
        
        color: #000;
        letter-spacing: 0;
        line-height: 17.6px!important;
      margin-top: 0.3rem !important;
      margin-left: 0rem !important;
  }

    .radio_label{
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .support-type details summary::after{
    padding-right: 12px!important;
  }
  .filter-results{
    padding-top: 0!important;
    padding-left: 0!important;
      /* padding-bottom: 12px!important; */
      margin-top: 0!important;
  }

  .filter-results .card-header {
    /* border: 1px solid #000; */
    /* background: #ffffff !important; */
    padding-left: 0!important;
    padding-top: 0!important;
}

.filterresults-head-box{
  padding-left: 0!important;
}

.radio_label li{
  padding-bottom: 8px;
}

.support-type details {
  padding-left: 12px !important;
}

 .mob-btn-apply, .mob-btn-call {
      border-radius: 40px;
      width: 100%;

  }
    .mob-btn-apply {
      background-color: #ffb91d;
      color: #000;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 17px;
  }
   .mob-btn-call {
      background-color: #ffb91d;
      color: #fff;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 18px;
  }
  .mob-btn-call:active, .mob-btn-call:focus, .mob-btn-call:hover {
  border: none!important;
  background: #000!important;
  color: #fff!important;
   font-size: 16px;
      letter-spacing: 0;
      line-height: 18px;
}
.mob-btn-apply:active, .mob-btn-apply:focus, .mob-btn-apply:hover {
  border: none!important;
  background: #ffb91d!important;
  color: #000!important;
   font-size: 16px;
      letter-spacing: 0;
      line-height: 18px;
}

.break-line{display: block!important;}
details{
padding-left: 0!important;
  padding-right: 0!important;
}
details summary::after {
padding-top: 8px;
}
.break-line{
padding-left: 12px;
padding-right: 12px;

}
.product-card{border: unset;}
.division-search{
padding: 0!important;
}
.card{
border-left: unset;
  border-right: unset;
  margin-left: 12px;
  margin-right: 12px;
  border-radius: 0!important;
}

.sidebar-search-box-container{
margin-left: 0;
  margin-right: 0;
}
.filter-area{height: 100%;}
.main-filter-div{
display: flex;
flex-direction: column;
height: 100%;
}
#accordion{
flex: 1;
}

.division-search{
padding: 0px;
}
/* .show-expanded .expand-list{
position: absolute;
   left: 0;
  
   color: #000;
   font-size: 13.3px!important;
   line-height: 17.6px!important;
   margin-top: 0.3rem !important;
    margin-left: 0.3rem !important;

} */

.srch-btn .btn i {
font-size: 24px;
font-weight: 400;
padding: 1px;
}
.all-filter-img{
position: absolute;
  margin-left: -2rem;
  /* margin-top: 0.1rem; */
}

.all-filter-img img{
width: 25px;
}

.search-box {
border: unset;

}
.mob-all-filter{display: block;}
.hide-mobile{display: none!important;}
.hide-largescreen{display: block!important;}
.mob-all-filter{
text-align: center;
margin-top: 0;
      margin-bottom: 1rem;
}

  .sidepanel  {
      /* width: 0; */
      position: fixed;
      z-index: 1;
      /* height: 250px; */
      top: 0;
      left: 0;
      background-color: #fff;
      overflow-x: hidden;
      transition: 0.5s;
      padding-top: 60px;
    
    }
    
    /* .sidepanel a {
      padding: 0;
      text-decoration: none;
      font-size: 16px;
      color: #000;
      display: block;
      transition: 0.3s;
    } */
    
    /* .sidepanel a:hover {
      color: #fff;
    } */
    
    .sidepanel .closebtn {
      position: absolute;
      top: 0;
      right: 25px;
      font-size: 36px;
      /* font-size: 36px; */
      /* font-size: 30px; */
      color: #000;
      text-decoration: none;
    }
    
    .openbtn {
      font-size: 20px;
      cursor: pointer;
      /* background-color: #111; */
      color: #000;
      padding: 10px 15px;
      border: none;
    }
    
    .openbtn:hover {
      background-color:#444;
    }

    .mob-filters{text-align: center;width: 343px;}

    .sidehead-box {
      border-radius: 4px 4px 1px 1px;
      background-color: #fff;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 12px;
      color: #000;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px;
  }

  .sidebar-search-box-container {
    margin-top: 0!important;
}



details {
/* position: relative;
font-size: 16px;
width: 100%; */
padding-left: 0.7rem;
padding-bottom: 0.5rem;
padding-right: 0.7rem;
padding-top: 0.5rem;
}

.sidepanel {
/* width: 0; */

position: fixed;
z-index: 999;
/* height: 250px; */
top: 0;
left: 0;
background-color: #fff;
overflow-x: hidden;
transition: 0.5s;
/* padding-top: 60px; */
/* margin: 0; */
}

.mob-all-filter .openbtn {
font-size: 20px;
cursor: pointer;
background-color: #111;
color: #fff;
padding: 10px 15px;
/* border: none; */
width: 343px;
border-radius: 40px;
margin-top: 0.5rem;
}
/* The Overlay (background) */
/* .sidebarOverlay {
background:rgba(0, 0, 0, 0.5) !important;
} */

.sidepanel{
border-top: 36px solid #FFB91D;
width: 50% !important;
height: 100vh;
}

#body-overlay {
width: 100vw;
height: 100vh;
display: none;
position: fixed;
z-index: 999;
top: 0;
overflow: hidden;
background: rgba(24, 22, 22, 0.5);
}

#body-overlay1 {
width: 100vw;
height: 100vh;
display: none;
position: fixed;
z-index: 999;
top: 0;
overflow: hidden;
background: rgba(24, 22, 22, 0.5);
}
#body-overlay2 {
width: 100vw;
height: 100vh;
display: none;
position: fixed;
z-index: 999;
top: 0;
overflow: hidden;
background: rgba(24, 22, 22, 0.5);
}

.mob-btn-call{
background-color: #000;

border-radius: 40px;
width: 100%;
}

.mob-btn-apply{
background-color: #FFB91D;

border-radius: 40px;
width: 100%;
color: #000;
}
.bottom-btn{
margin-top: 1rem;
  margin-left: 12px;
  margin-right: 12px;
}
.sidehead-box{
font-size: 16px;
font-weight: 500;
letter-spacing: 0;
line-height: 20px;
}
.srch-input input[type="text"]{
font-size: 16px;
letter-spacing: 0;
line-height: 20px;
}
.filter-label label{
font-size: 13.3px;
letter-spacing: 0;
line-height: 16.63px;
}
details summary{
color: #000;
font-size: 13.3px;
font-weight: 500;
letter-spacing: 0;
line-height: 16.63px;
margin-bottom: 0px;
padding-bottom: 8px;
      padding-top: 8px;
}
.filter-label input[type="checkbox"]{
height: 12px;
width: 12px;
border: 1px solid #000000;
border-radius: 2px;
background-color: #000000;
}

.filters_search{
font-size: 19.2px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -2rem;
  margin-bottom: 16px;
  margin-left: 15px
}
  .mob-btn-apply, .mob-btn-call {
      border-radius: 40px;
      width: 100%;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 18px;
  }
      .mob-btn-apply {
      background-color: #ffb91d;
      color: #000;
  }
}


@media (min-width: 768px) and (max-width: 1023px)  {  

  .filter-results .card-header {
    border-left: unset !important;
    border-right: unset !important;
  }

  .support-type .expand-list{
    font-size: 13.3px;
    line-height: 17.6px;
    letter-spacing: 0;
    margin-top: 0rem !important;
        margin-left: .9rem !important;
  }

 .bottom-btn{
  display: block;
}
  .radio_label{
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .support-type details summary::after{
    padding-right: 12px!important;
  }
  .filter-results{
    padding-top: 0!important;
    padding-left: 0!important;
      /* padding-bottom: 12px!important; */
      margin-top: 0!important;
  }

  .filter-results .card-header {
    /* border: 1px solid #000; */
    /* background: #ffffff !important; */
    padding-left: 0!important;
    padding-top: 0!important;
}

.filterresults-head-box{
  padding-left: 0!important;
}

.radio_label li{
  padding-bottom: 8px;
}

.support-type details {
  padding-left: 12px !important;
}

   .mob-btn-call:active, .mob-btn-call:focus, .mob-btn-call:hover {
  border: none!important;
  background: #000!important;
  color: #fff!important;
   font-size: 16px;
      letter-spacing: 0;
      line-height: 18px;
}
.mob-btn-apply:active, .mob-btn-apply:focus, .mob-btn-apply:hover {
  border: none!important;
  background: #ffb91d!important;
  color: #000!important;
   font-size: 16px;
      letter-spacing: 0;
      line-height: 17px;
}.show-expanded .view-more-list {
  font-size: 13.3px;
  /* margin-left: 5px; */
  color: #000;
  letter-spacing: 0;
  line-height: 17.6px;
  margin-left: 0.2rem;
}
  .mob-btn-apply, .mob-btn-call {
      border-radius: 40px;
      width: 100%;
     
  }
      .mob-btn-apply {
      background-color: #ffb91d;
      color: #000;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 17px;
  }
   .mob-btn-call {
      background-color: #ffb91d;
      color: #fff;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 18px;
  }
  .break-line{display: block!important;}
details{
padding-left: 0!important;
  padding-right: 0!important;
}
.break-line{
  padding-left: 12px;
  padding-right: 12px;

 }

.product-card{border: unset;}
.division-search{
  padding: 0!important;
}

 .card{
  border-left: unset;
    border-right: unset;
    margin-left: 12px;
    margin-right: 12px;
    border-radius: 0!important;
}

.sidebar-search-box-container{
  margin-left: 0;
    margin-right: 0;
}

input::placeholder{
  color: #000000;
  font-size: 16px;
letter-spacing: 0;
line-height: 20px;
}

.filter-area{height: 100%;}
.main-filter-div{
  display: flex;
  flex-direction: column;
  height: 100%;
}
#accordion{
  flex: 1;
}
.division-search{
  padding: 0px;
}
.expand-list{
position: absolute;
   left: 0;
   margin-top: 0rem!important;
}
.show-expanded .view-more-list {

font-size: 13.3px;
      color: #000;
      margin-left: 1.4rem;
}

  .expand-list, .view-more-list {
      padding: 0;
      text-decoration: none;
      font-size: 13.3px;
      color: #000;
      display: block;
      transition: .3s;
     
  }
.card-header {
  padding: 12px;
}

.srch-btn .btn i {
  font-size: 24px;
  font-weight: 400;
  padding: 1px;
}

#body-overlay1{
width: 100vw;
height: 100vh;
display: none;
position: fixed;
z-index: 999;
top: 0;
overflow: hidden;
background: rgba(24, 22, 22, 0.5);
/* display: block; */
}

#body-overlay2{
  width: 100vw;
  height: 100vh;
  display: none;
  position: fixed;
  z-index: 999;
  top: 0;
  overflow: hidden;
  background: rgba(24, 22, 22, 0.5);
  /* display: block; */
  }

.all-filter-img{
  position: absolute;
    margin-left: -2rem;
    /* margin-top: 0.2rem; */
}

.all-filter-img img{
  width: 20px;
}

.search-box {
  border: unset;

}
.mob-all-filter{display: block;}
/* .sidepanel{display:none ; } */
  /* margin-top: 4rem; */
  
  .filters_search{
    font-size: 19.2px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
      margin-top: -3rem;
      margin-bottom: 16px;
      margin-left: 15px
  }
.hide-mobile{display: none!important;}
.hide-largescreen{display: block!important;}
.mob-all-filter{
  text-align: center;
  margin-top: 1rem;
}

    .sidepanel  {
        /* width: 0; */
        position: fixed;
        z-index: 1;
        /* height: 250px; */
        top: 0;
        left: 0;
        background-color: #fff;
        overflow-x: hidden;
        transition: 0.5s;
        padding-top: 60px;
      
      }
      
      /* .sidepanel a {
        padding: 8px 8px 8px 32px;
        text-decoration: none;
        font-size: 25px;
        color: #818181;
        display: block;
        transition: 0.3s;
      }
      
      .sidepanel a:hover {
        color: #fff;
      } */
      
      .sidepanel .closebtn {
        position: absolute;
        top: 0;
        right: 25px;
        font-size: 36px;
        color: #000;
      text-decoration: none;
      }
      
      .openbtn {
        font-size: 20px;
        cursor: pointer;
        /* background-color: #111; */
        color: #000;
        padding: 10px 15px;
        border: none;
      }
      
      .openbtn:hover {
        background-color:#444;
      }

      .mob-filters{text-align: center;width: 264px;}

      .sidehead-box {
        border-radius: 4px 4px 1px 1px;
        background-color: #fff;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 12px;
        color: #000;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 20px;
    }

    .sidebar-search-box-container {
      margin-top: 0!important;
  }

  details summary {
  color: #000;
    padding-bottom: 0;
    font-size: 13.3px;
font-weight: 500;
letter-spacing: 0;
line-height: 16.63px;
padding-bottom: 8px;
      padding-top: 8px;
}
details summary::after {
  padding-top: 8px;
}

details {
  /* position: relative;
  font-size: 16px;
  width: 100%; */
  padding-left: 0.7rem;
  padding-bottom: 0.5rem;
  padding-right: 0.7rem;
  padding-top: 0.5rem;
}

.sidepanel {
  /* width: 0; */
  position: fixed;
  z-index: 999;
  /* height: 250px; */
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  /* margin: 0; */
}

.mob-all-filter .openbtn {
  font-size: 16px;
  cursor: pointer;
  background-color: #111;
  color: #fff;
  padding: 5px 15px;
  /* border: none; */
  width: 264px;
  border-radius: 40px;
  margin-top: 12px;
}
.hide-largescreen {
  display: block;
}

.filter-list {
margin-top: 0.5rem;
      margin-bottom: 0.5rem;
}
/* The Overlay (background) */
/* .sidebarOverlay {
  background:rgba(0, 0, 0, 0.5) !important;
} */

.sidepanel{
  border-top: 36px solid #FFB91D;
  width: 50% !important;
  height: 100vh;
}

#body-overlay {
  width: 100vw;
  height: 100vh;
  display: none;
  position: fixed;
  z-index: 3;
  top: 0;
  overflow: hidden;
  background: rgba(24, 22, 22, 0.5);
}


.mob-btn-call{
  background-color: #000;
  
  border-radius: 40px;
  width: 100%;
}

.mob-btn-apply{
  background-color: #FFB91D;
  
  border-radius: 40px;
  width: 100%;
}
.bottom-btn{
  margin-top: 1rem;
    margin-left: 12px;
    margin-right: 12px;
}
.sidehead-box{
  font-size: 16px;
font-weight: 500;
letter-spacing: 0;
line-height: 20px;
}
.srch-input input[type="text"]{
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
}
.filter-label label{
  font-size: 13.3px;
  letter-spacing: 0;
  line-height: 16.63px;
}
input::placeholder{
  color: #000000;
  font-size: 16px;
letter-spacing: 0;
line-height: 20px;
}

}
@media only screen and (min-width: 1440px) {
    .radio_label{
    list-style: none;
    margin: 0;
    padding: 0;
  } 

  .support-type .show-expanded .expand-list{
    margin-top: 0.3rem !important;
    }
.show-expanded .expand-list{
  margin-top: 0.1rem !important;
  }
.show-expanded .view-more-list {
  font-size: 13.3px;
      margin-left: 0.7rem !important;
  color: #000;
  letter-spacing: 0;
  line-height: 17.6px;
  
}

  
.bottom-btn{display: none;}
  input::placeholder{
    color: #000000;
    font-size: 16px;
letter-spacing: 0;
line-height: 20px;
  }
  /* .sidehead-box{
    display: flex;
    
  } */
  .filterby_clear_all{
    /* position: relative;
  right: 0;
  left: 10.5rem; */
  text-decoration: underline;
  float: right;
  margin-top: -1.2rem;
        margin-right: 0.6rem;
  }

  .srch-btn .btn {
    min-width: 30px;
    min-height: 26px;
    border-radius: 0 4px 4px 0;
    border: 1px solid #000;
    border-left: unset;
    padding: 7px;
}
.search-box {
  display: flex;
  border: unset;
  border-radius: 4px;
  background-color: #fff;
}
  .mob-all-filter{display: none;}
  .sidehead-box {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
  }
  .srch-input input[type="text"]{
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
  }
  .filter-label label{
    font-size: 13.3px;
    letter-spacing: 0;
    line-height: 16.63px;
  }
  details summary{
    color: #000000;
    font-size: 16px;
font-weight: 500;
letter-spacing: 0;
line-height: 20px;
margin-bottom: 8px;
  }
  .filter-label input[type="checkbox"]{
    height: 12px;
  width: 12px;
  border: 1px solid #000000;
  border-radius: 2px;
  background-color: #000000;
  }
  .srch-btn .btn i {
    font-size: 24px;
    font-weight: 400;
    padding: 1px;
}
}

@media (min-width: 375px) and (max-width: 425px)  {

  .show-responsive{
    display: block;
  }

  .filter-results .card-header {
    border-left: unset !important;
    border-right: unset !important;
  }

  .support-type .show-expanded .expand-list {
    position: absolute;
    left: 0;
    margin-top: 0.3rem !important;
    padding-left: 0 !important;
    margin-left: 1rem!important;
}

    .show-expanded .view-more-list {
        font-size: 13.3px;
        margin-left: 1.2rem!important;
        color: #000;
        letter-spacing: 0;
        line-height: 17.6px;
        /* margin-top: -6rem; */
    }
    .show-expanded .expand-list {
    position: absolute;
    left: 0;
    margin-top: 0.3rem !important;
    padding-left: 0 !important;
    margin-left: 0.2rem!important;
}

   .bottom-btn{
  display: block;
}

    .radio_label{
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .support-type details summary::after{
    padding-right: 12px!important;
  }
  .filter-results{
    padding-top: 0!important;
    padding-left: 0!important;
      /* padding-bottom: 12px!important; */
      margin-top: 0!important;
  }

  .filter-results .card-header {
    /* border: 1px solid #000; */
    /* background: #ffffff !important; */
    padding-left: 0!important;
    padding-top: 0!important;
}

.filterresults-head-box{
  padding-left: 0!important;
}

.radio_label li{
  padding-bottom: 8px;
}

.support-type details {
  padding-left: 12px !important;
}

 .mob-btn-apply, .mob-btn-call {
      border-radius: 40px;
      width: 100%;

  }
    .mob-btn-apply {
      background-color: #ffb91d;
      color: #000;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 17px;
  }
   .mob-btn-call {
      background-color: #ffb91d;
      color: #fff;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 18px;
  }

 .mob-btn-call:active, .mob-btn-call:focus, .mob-btn-call:hover {
  border: none!important;
  background: #000!important;
  color: #fff!important;
   font-size: 16px;
      letter-spacing: 0;
      line-height: 18px;
}
.mob-btn-apply:active, .mob-btn-apply:focus, .mob-btn-apply:hover {
  border: none!important;
  background: #ffb91d!important;
  color: #000!important;
  font-size: 16px;
      letter-spacing: 0;
      line-height: 17px;
}
   .show-expanded .view-more-list {
        font-size: 13.3px;
        /* margin-left: 5px; */
        color: #000;
        letter-spacing: 0;
        line-height: 17.6px;
    }
  .break-line{display: block!important;}
.break-line{
padding-left: 12px;
padding-right: 12px;

}
.product-card{border: unset;}
.division-search{
  padding: 0!important;
}
.card{
  border-left: unset;
    border-right: unset;
    margin-left: 12px;
    margin-right: 12px;
    border-radius: 0!important;
}

.sidebar-search-box-container{
  margin-left: 0;
    margin-right: 0;
}

input::placeholder{
  color: #000000;
  font-size: 16px;
letter-spacing: 0;
line-height: 20px;
}
.filter-area{height: 100%;}
.main-filter-div{
  display: flex;
  flex-direction: column;
  height: 100%;
}
#accordion{
  flex: 1;
}
.division-search{
  padding: 0px;
}
.sidehead-box {
  border-radius: 4px 4px 1px 1px;
  background-color: #fff;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
}

.sidebar-search-box-container {
margin-top: 0!important;
}

details summary {
padding-bottom: 8px;
}

details {
      padding-left: 0!important;
}

.sidepanel {
/* width: 0; */
position: fixed;
z-index: 999;
/* height: 250px; */
top: 0;
left: 0;
background-color: #fff;
overflow-x: hidden;
transition: 0.5s;
padding-top: 60px;
/* margin: 0; */
}

.mob-all-filter .openbtn {
font-size: 16px;
cursor: pointer;
background-color: #111;
color: #fff;
padding: 5px 15px;
/* border: none; */
width: 264px;
border-radius: 40px;
margin-top: 12px;
}
.hide-largescreen {
display: block;
}

.filter-list {
margin-top: 0.5rem !important;
      margin-bottom: 0.5rem;
}
/* The Overlay (background) */
/* .sidebarOverlay {
background:rgba(0, 0, 0, 0.5) !important;
} */

.sidepanel{
border-top: 36px solid #FFB91D;
width: 100% !important;
height: 100vh;
}

#body-overlay {
width: 100vw;
height: 100vh;
display: none;
position: fixed;
z-index: 3;
top: 0;
overflow: hidden;
background: rgba(24, 22, 22, 0.5);
}


.mob-btn-call{
background-color: #000;

border-radius: 40px;
width: 100%;
}

.mob-btn-apply{
background-color: #FFB91D;

border-radius: 40px;
width: 100%;
color: #000;
}
.bottom-btn{
margin-top: 1rem;
margin-left: 12px;
margin-right: 12px;
}

.card-header{
  padding: 12px;
}

.srch-btn button i{
  padding: 1px;
}
.all-filter-img{
  position: absolute;
    margin-left: -2rem;
    margin-top: 0.1rem;
}

.all-filter-img img{
  width: 20px;
  /* height: 40px; */
}

.mob-all-filter{display: block;}
.search-box {
  border: unset;
 
}

.filters_search{
  font-size: 19.2px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: -2rem;
    margin-bottom: 16px;
    margin-left: 15px
}

 .show-expanded .view-more-list {
  font-size: 13.3px;
  margin-left: 5px;
  color: #000;
  letter-spacing: 0;
  line-height: 17.6px;
}

/* .show-1024{display: none!important;} */
.mob-all-filter{text-align: center;}
/* .hide-mobile{display: none!important;}
.hide-largescreen{display: block!important;} */

    .sidepanel  {
        width: 100%;
        position: fixed;
        z-index: 1;
        /* height: 250px; */
        /* height: 100vh; */
        top: 0;
        left: 0;
        background-color: #fff;
        overflow-x: hidden;
        transition: 0.5s;
        padding-top: 60px;
      
      }
      
      /* .sidepanel a {
        padding: 8px 8px 8px 32px;
        text-decoration: none;
        font-size: 25px;
        color: #818181;
        display: block;
        transition: 0.3s;
      } */
      
      /* .sidepanel a:hover {
        color: #fff;
      } */
      
      .sidepanel .closebtn {
        position: absolute;
        top: 12px;
        right: 25px;
        font-size: 30px;
        color: #000;
        text-decoration: none;
      }
      
      .openbtn {
        font-size: 20px;
        cursor: pointer;
        /* background-color: #111; */
        color: #000;
        padding: 10px 15px;
        border: none;
      }
      
      .openbtn:hover {
        background-color:#444;
      }

      .mob-filters{text-align: center;width: 264px;}

    .sidebar-search-box-container {
      margin-top: 0!important;
  }


.sidepanel {
  /* width: 0; */
  position: fixed;
  z-index: 999;
  /* height: 250px; */
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  /* margin: 0; */
}

.mob-all-filter .openbtn {
  font-size: 16px;
  cursor: pointer;
  background-color: #111;
  color: #fff;
  padding: 5px 15px;
  /* border: none; */
  width: 100%;
  border-radius: 40px;
  margin-top: 12px;
}

/* .filter-list {
margin-top: -0.5rem !important;
margin-bottom: 16px;
} */
/* The Overlay (background) */
/* .sidebarOverlay {
  background:rgba(0, 0, 0, 0.5) !important;
} */

.sidepanel{
  /* border-top: 36px solid #FFB91D;
  width: 50% !important; */
  height: 100vh;
}

#body-overlay {
  width: 100vw;
  height: 100vh;
  display: none;
  position: fixed;
  z-index: 3;
  top: 0;
  overflow: hidden;
  background: rgba(24, 22, 22, 0.5);
}


.mob-btn-call{
  background-color: #000;
  
  border-radius: 40px;
  width: 100%;
}

.mob-btn-apply{
  background-color: #FFB91D;
  
  border-radius: 40px;
  width: 100%;
  color: #000;
}
.bottom-btn{
  margin-top: 1rem;
    margin-left: 12px;
    margin-right: 12px;
}
.sidehead-box{
  font-size: 16px;
font-weight: 500;
letter-spacing: 0;
line-height: 20px;
}
.srch-input input[type="text"]{
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
}
.filter-label label{
  font-size: 13.3px;
  letter-spacing: 0;
  line-height: 16.63px;
}
details summary{
  font-size: 13.3px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 16.63px;
      color: #000;
}
}

@media (min-width: 1920px) and (max-width: 2560px)  {

  .radio_label{
    list-style: none;
    margin: 0;    padding: 0;
  }
.show-expanded .view-more-list{
font-size: 13.3px!important;
margin-left: 1rem;
color: #000;
letter-spacing: 0;
line-height: 17.6px!important;

}
.show-expanded .expand-list{
position: absolute;
   left: 0;
  margin-top: 0.2rem !important;
   color: #000;
   font-size: 13.3px!important;
   letter-spacing: 0;
   line-height: 17.6px!important;

}
.bottom-btn{display: none;}

.division-search{
  padding: 0!important;
}
input::placeholder{
  color: #000000;
  font-size: 16px;
letter-spacing: 0;
line-height: 20px;
}

/* .sidehead-box{
  display: flex;
  
} */
.filterby_clear_all{
  float: right;
  margin-top: -1.2rem;
  margin-right: 0.6rem;
  /* position: relative;
right: 0;
left: 18rem; */
text-decoration: underline;
}
.srch-btn .btn {
  min-width: 30px;
  min-height: 26px;
  border-radius: 0 4px 4px 0;
  border: 1px solid #000;
  border-left: unset;
  padding: 7px;
}
.search-box {
display: flex;
border: unset;
border-radius: 4px;
background-color: #fff;
}

.mob-all-filter{display: none;}

.sidehead-box{
  font-size: 16px;
font-weight: 500;
letter-spacing: 0;
line-height: 20px;
}
.srch-input input[type="text"]{
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
}
.filter-label label{
  font-size: 13.3px;
  letter-spacing: 0;
  line-height: 16.63px;
}
details summary{
  color: #000000;
  font-size: 16px;
  /* letter-spacing: 0; */
  /* line-height: 16.63px; */
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
}
.srch-btn .btn i {
  font-size: 24px;
  padding: 1px;
  font-weight: 400;
}
}


@media screen and (min-width: 2560px) {
  /* .filterby_clear_all {
    position: relative;
    right: 0;
    left: 28rem;
    text-decoration: underline;
} */
}

.filter-results .card-header {
 
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  border-top: unset;
  border-radius: 0px 0px 4px 4px;
}